<template>
  <div v-if="!isEmpty(sliderTeaserResults)">
    <span class="global-placeholder-el-large"></span>

    <Overview :content="{ teaserItems: sliderTeaserResults }" />
  </div>
</template>

<script lang="ts" setup>
import type { TeaserBox } from '@components/Teaser/Box/models';
import type { RawEventListItemFragment } from '@gql/fragments/__generated/RawEventListItem';
import type { RawWidgetConfigEventDefFragment } from '@gql/fragments/__generated/RawWidgetConfigEventDef';
import { WhlModuleType } from '@models/WhlModuleType';
import { WhlPageType } from '@models/WhlPageType';
import isEmpty from '@utils/isEmpty';

definePageMeta({
  whlModuleType: WhlModuleType.Event,
  whlPageType: WhlPageType.Overview,
  //middleware: ['whl-module-type-tracker', 'whl-page-type-tracker'],
});

const { t, d } = useI18n();

const widgetConfig = await useWidgetConfig();

ensureWhlModuleIsEnabled(widgetConfig, WhlModuleType.Event);
const widgetTypeConfig = useWidgetTypeConfig(
  widgetConfig
) as ComputedRef<RawWidgetConfigEventDefFragment>;

const searchStore = useSearchStore();
searchStore.readStateFromRoute();

useWhlSeoMeta({
  title: t('pages.events.overview.title'),
});

const baseFilter = buildEventBaseFilter(widgetConfig);

const sliderTeaserResults = await Promise.all(
  (widgetTypeConfig.value.entryPageCategories ?? [])
    .filter(
      (category) =>
        !isEmpty(category.category?.id) &&
        !isEmpty([category.label, category.category?.i18nName])
    )
    .map(async (category) => {
      const userFilter = { categories: { oneOf: [category.category!.id!] } };
      const { data: relatedRecords } = await useAsyncData(
        `eventHighlighted_${category.category?.id}`,
        () =>
          fetchEvents(widgetConfig, userFilter, baseFilter).then(
            (data) => data.events.value
          )
      );

      return {
        type: 'TeaserBox' as const,
        title: {
          main: category.label || category.category!.i18nName!,
        },
        items:
          relatedRecords.value?.map(
            (event: RawEventListItemFragment): TeaserBox => {
              const {
                nextEventDateAndTimeConsideringDate,
                allEventDatesLinkList,
              } = transformEventDateData(event, { t, d }, 'medium');

              const { toBaseImage: image } = mapImxPlatformMedia(event.image);

              const infoTagItems = [];
              if (
                !isEmpty(nextEventDateAndTimeConsideringDate.value?.date) &&
                !isEmpty(nextEventDateAndTimeConsideringDate.value?.time)
              ) {
                infoTagItems.push(
                  nextEventDateAndTimeConsideringDate.value!.date!
                );
                infoTagItems.push(
                  nextEventDateAndTimeConsideringDate.value!.time!
                );
              }

              return {
                id: event.id,
                moduleType: event.__typename as WhlModuleType,
                fig: convertImxPlatformImage(image),
                title: event.title,
                to: toValue(buildLinkToDetailsPage(event.permaLink)),
                infoTags: {
                  items: infoTagItems,
                },
                datelist: !isEmpty(allEventDatesLinkList.value)
                  ? allEventDatesLinkList.value
                  : null,
                postAddress: !isEmpty(event.contact?.contact1?.contactName)
                  ? {
                      items: [
                        {
                          icon: 'ion:location-sharp',
                          text: event.contact!.contact1!.contactName!,
                        },
                      ],
                    }
                  : null,
              };
            }
          ) ?? [],
      };
    })
);
</script>
